import React from 'react'
import "../css/How.css"
import { Link } from 'react-router-dom'

const Card = ({item}) => {
    return(
        <div className='card'>
            <div>
                <h2>{item.id}</h2>
                <h3>{item.title}</h3>
            </div>
            <p>{item.content}</p>
            
        </div>
    )
}

const How = () => {
    const data = [
        {
            id: 1,
            title: "Intent and Focus",
            content: "I have a strong desire and willpower to achieve any intended result with over 20 years of experience. A gift that was bestowed upon me by my fore fathers."
        },
        {
            id: 2,
            title: "Rituals and spells",
            content: "These are structured actions  & words performed to invoke and direct energies. They often involve specific materials, symbols, incantations and gestures."
        },
        {
            id: 3,
            title: "Pyschological Factor",
            content: "Belief, visualisation & emotional intensity are crucial for the success of magic. Your state of mind and emotional focus impacts the efficacy of spells & rituals"
        },
        {
            id: 4,
            title: "Energy Manipulation",
            content: "Everything in the universe is connected by energy and by altering this, I can influence events, people & circumstances by chanelling personal & environments energy."
        },
        {
            id: 5,
            title: "Invocation and Evocation",
            content: "This involves invoking spiritual entities such as demons, spirits or dieties to assist in achieving the desired outcome eg power, knowldege or direct intervention."
        },
        {
            id: 6,
            title: "Sacrifice and Offerings",
            content: " These are made to appease or gain favour from the spiritual entities. These can range from simple offerings like food, drink to more elaborate sacrifices."
        }
    ]
    return (
        <div className='how'>
            <h2>How It Works</h2>
            <div className='howItems'>
                {data.map(item => (
                    <Card key={item.id} item={item}/>
                ))}
            </div>
        </div>
    )
}

export default How