import React, { state, useState } from 'react'
import { motion } from 'framer-motion'
import "../css/Contact.css"
import { dark, light } from './Colors'
import { useLightDarkContext } from './LightDarkContext'

const Contact = () => {
    const { isDark } = useLightDarkContext()
    const initial = {
        name: '',
        address: '',
        subject: '',
        message: ''
    }
    const [data, setData] = useState(initial)
    const { name, address, subject, message } = data

    const [errors, setErrors] = useState({})

    // Validation function
    const validateForm = () => {
        let valid = true
        const newErrors = {}

        // Validate name
        if (!name.trim()) {
            newErrors.name = 'Name is required'
            valid = false
        }

        // Validate address
        if (!address.trim()) {
            newErrors.address = 'Address is required'
            valid = false
        }

        // Validate subject
        if (!subject.trim()) {
            newErrors.subject = 'Subject is required'
            valid = false
        }

        // Validate subject
        if (!message.trim()) {
            newErrors.message = 'Message is required'
            valid = false
        }

        setErrors(newErrors)
        return valid
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setData(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // Validate form
        const isValid = validateForm()

        if (isValid) {
            // Clear form or perform further actions
            const recipientEmail = 'info@drsosu.com';
            const subject = encodeURIComponent(data.subject);

            if (subject) {
                const body = encodeURIComponent(`Greetings Dr. Soso\n ${data.message}\n${data.address}.`);
                window.location.href = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;
            }
            setData(initial) // Reset form data
        } else {
            console.log("Form has errors. Please fix them.")
        }
    }

    return (
        <div className='contact'>
            <div className='text-center w-100 pt-2 pb-2'>
                <motion.h2 initial={{ y: -300 }} animate={{ y: 0 }} style={{ color: isDark ? dark.color : light.color }}>Contact us!</motion.h2>
                <p className='text-white one w-100 w-md-75 m-auto'>Dr. Sosu Requires No Money & does this for the Love of Spiritual Powers that were passed to him from his fore Fathers and has Experience of over 20 Years. Once Contacted, Results are Expected within 24 Hours</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='div' >
                    <div>
                        <label>Name</label>
                        <input type='text' value={name} onChange={handleInputChange} name='name' placeholder='name' />
                        {errors.name && <span className="error">{errors.name} <b className='text-danger fw-bold'>*</b></span>}
                    </div>
                    <div>
                        <label>Address</label>
                        <input type='text' value={address} onChange={handleInputChange} name='address' placeholder='address' />
                        {errors.address && <span className="error">{errors.address} <b className='text-danger fw-bold'>*</b></span>}
                    </div>
                    <div>
                        <label>Subject</label>
                        <input type='text' value={subject} onChange={handleInputChange} name='subject' placeholder='subject' />
                        {errors.subject && <span className="error">{errors.subject} <b className='text-danger fw-bold'>*</b></span>}
                    </div>
                </div>
                <label>Message</label>
                <textarea name='message' onChange={handleInputChange} value={message} placeholder='message ...'></textarea>
                {errors.message && <span className="error">{errors.message} <b className='text-danger fw-bold'>*</b></span>}
                <button>Send Message</button>
            </form>
        </div>
    )
}

export default Contact