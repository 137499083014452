import React, { state, useState } from 'react'
import { motion } from 'framer-motion'
import "../css/Contact.css"
import { dark, light } from './Colors'
import { useLightDarkContext } from './LightDarkContext'

const About = () => {
    return (
        <div>About</div>
    )
}

export default About