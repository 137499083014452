import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

export const useScrollContext = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('LightDarkContext must be used within an LightDarkProvider');
  }
  return context;
};

export const ScrollContextProvider = ({ children }) => {
  const [scrollTo, setScrollTo] = useState('Home');

  const value = {
    scrollTo, 
    setScrollTo
  };

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};