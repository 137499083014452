// ActivePageContext.js
import React, { createContext, useContext, useState } from 'react';

const LightDarkContext = createContext();

export const useLightDarkContext = () => {
  const context = useContext(LightDarkContext);
  if (!context) {
    throw new Error('LightDarkContext must be used within an LightDarkProvider');
  }
  return context;
};

export const LightDarkProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(false);

  const value = {
    isDark,
    setIsDark,
  };

  return <LightDarkContext.Provider value={value}>{children}</LightDarkContext.Provider>;
};