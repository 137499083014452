import React from 'react'
import { Audio, RotatingSquare, Grid } from 'react-loader-spinner'

const Loader = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center bg-secondary' style={{ height: "100vh" }}>
            <Grid
                visible={true}
                height="80"
                width="80"
                color="orange"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass="grid-wrapper"
            /><br />
            <p>Loading Page, Please Wait ...</p>
        </div>
    )
}

export default Loader