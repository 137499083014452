import { Route, Routes } from "react-router-dom"
import './App.css';
import 'animate.css';
import Home from "./components/Home";
import { ScrollContextProvider } from "./components/ScrollContext";

function App() {
  return (
    <div className="dark">
      <ScrollContextProvider>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </ScrollContextProvider>
    </div>
  );
}

export default App;
