import React from 'react'
import "../css/Testimonials.css"
import { dark, light } from './Colors'
import { useLightDarkContext } from './LightDarkContext'

const Testimonials = () => {
    const { isDark } = useLightDarkContext()
    return (
        <div className='testimonials'>
        
            
            <div className='tale mt-4'>
                <div className='text-center'>
                    <img src='/images/logo.png' />
                </div>
                <div>
                    <i className='fa fa-quote-left'></i>
                    <em>Magic is neither good nor evil, it is a force that can be wielded for either purpose. In the realm of magic, there are no shortcuts. Only discipline and practice can get you to greater heights.</em>
                    <h4>Black magic sanction</h4>
                </div>
            </div>
            
        </div>
    )
}

export default Testimonials