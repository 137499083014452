import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='text-center' >
            
            <hr className='bg-white text-white w-75 m-auto' style={{height: "1px"}} />
            <div className='p-4 d-flex gap-4 justify-content-center text-white'>
                <Link className='m-0 p-0' to="https://www.facebook.com/profile.php?id=61559826197646" ><i className='fab fa-facebook'></i></Link>
                <Link className='m-0 p-0'  to="https://wa.me/256766716284?text=Hi,%20I'm%20interested%20in%20your%20services"><i className='fab fa-whatsapp'></i></Link>
                <Link className='m-0 p-0' ><i className='fab fa-instagram'></i></Link>
                <Link className='m-0 p-0' ><i className='fab fa-linkedin'></i></Link>
            </div>
            <p className='text-center p-2 bg-body-tertiary text-dark'>Powered by Cynaut Technologies Ltd</p>
        </div>
    )
}

export default Footer