import Form from 'react-bootstrap/Form';
import React from 'react'
import { useLightDarkContext } from './LightDarkContext';
import { Link } from 'react-router-dom';

const LightDark = () => {
    const { isDark, setIsDark } = useLightDarkContext()

    const handleSwitchChange = () => {
        setIsDark(!isDark); // Toggle the state of isDark
    };
    return (
        <Form className='d-flex justify-content-end align-items-center'>
            <Link className='m-0 p-0 me-2 text-decoration-none'  to="https://wa.me/256766716284?text=Hi,%20I'm%20interested%20in%20your%20services"> <i className='fab fa-whatsapp'></i> whatsapp</Link>
            <Link to={'tel:+256766716284'} className='me-2 m-0 p-2 text-decoration-none'><i class="fa-solid fa-phone" style={{fontSize:"0.875rem"}}></i> +256766716284 </Link>
            <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                className='d-none'
                label={isDark ? <span className='text-dark'><i class="fa-solid fa-moon"></i> dark</span> : <span className='text-warning'><i class="fa-regular fa-sun"></i> light</span>}
                checked={isDark} // Controlled component: check state of isDark
                onChange={handleSwitchChange} // Call handleSwitchChange on change
            />
        </Form>
    );
}

export default LightDark