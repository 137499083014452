import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "../css/Services.css"
import { dark, light } from './Colors'
import { useLightDarkContext } from './LightDarkContext'
import { motion, useAnimate, usePresence, AnimatePresence } from 'framer-motion'
import { useScrollContext } from './ScrollContext'

const services = [
    {
        id: 1,
        name: "Spiritual Consultation and Guidance",
        img: "/images/service.svg",
        details: "Receive one on one consultations to explore your spiritual path and discuss your magical practices. These are confidential and tailored to your unique journey."
    },
    {
        id: 2,
        name: "Curse Removal or Reversal",
        img: "/images/face.svg",
        details: "If you believe you are under the influence of a curse, I can help. I offer comprehensive curse and hex removal services to cleanse your aura and restore harmony to your life."
    },
    {
        id: 3,
        name: "Love, Money and Prosperity spells",
        img: "/images/daily.svg",
        details: "For those who wish to perform their own rituals and spells, I offer carefully curated kits that include all necessary components and detailed instructions."
    }
]

const Services = () => {
    const { isDark } = useLightDarkContext()
    const [isPresent, safeToRemove] = usePresence()
    const [scope, animate] = useAnimate()
    const {setScrollTo} = useScrollContext()
    
    useEffect(() => {
        if (isPresent) {
            const enterAnimation = async () => {
                await animate(scope.current, { opacity: 1 })
                await animate("div", { opacity: 1, x: 0 })
            }
            enterAnimation()

        } else {
            const exitAnimation = async () => {
                await animate("div", { opacity: 0, x: -100 })
                await animate(scope.current, { opacity: 0 })
                safeToRemove()
            }

            exitAnimation()
        }
    }, [isPresent])

    return (
        <motion.div className='services'>
            <motion.h2 initial={{ y: -300 }} animate={{ y: 0 }} style={{ color: isDark ? dark.color : light.color }}>What I can do for You!</motion.h2>
            <p className='text-white one'>Illuminate the dark corners of the magical world and get a balanced
                perspective that empowers you.</p>
            <AnimatePresence>
                <div className='divided' ref={scope}  >
                    {services.map(service => (
                        <div key={service.id}>
                            <img src={service.img} alt={service.name} />
                            <Link>{service.name}</Link>
                            <em className='text-white'>{service.details}</em>
                        </div>
                        
                    ))}
                </div>
            </AnimatePresence>
            <div className='d-block d-md-flex align-items-center serviceInfo mt-4' >
                <div className='w-100 w-md-50 flex-grow'>
                    <h2 className='text-start'>Protective spells & Home Cleansing</h2>
                    <p className='two'>Ensure your home and personal spaces are safe guarded from negative energies and influences. I set up protective wards and perform thorough cleansings to maintain a harmonious environment.
                        Embark on a journey of discovery and transformation. Become a part of a community that respects and honors the powerful traditions of black magic.
                    </p>
                    <button onClick={()=>setScrollTo('Contact')}>Schedule Your Session</button>
                </div>
                <div className='w-100 w-md-50 flex-grow'>
                    <img src='/images/person.jpg' className='img-fluid' alt='some image wil be here' />
                </div>
            </div>
        </motion.div>
    )
}

export default Services