import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import LightDark from './LightDark';
import { useLightDarkContext } from './LightDarkContext';
import { useScrollContext } from './ScrollContext';


const NavBar = () => {
    const { isDark } = useLightDarkContext()
    const { scrollTo, setScrollTo } = useScrollContext()

    return (
        <>
            <LightDark />
            
            <Navbar expand="lg" sticky="top" bg='dark' data-bs-theme={ isDark ? 'dark' : ''} className="bg-body-tertiary" >{/* */}
                <Container>
                    <Navbar.Brand href="#home" className='fw-bold text-danger align-self-center d-flex align-items-center gap-4'>
                        <img
                            alt=""
                            src="/images/logo.png"
                            height="50"
                            className="d-inline-block align-top"
                        />{' '}
                        <span className='d-none d-md-inline text-secondary'>Mystical World of Black Magic!</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={()=>setScrollTo('Home')}>Home</Nav.Link>
                            <Nav.Link onClick={()=>setScrollTo('About')}>About us</Nav.Link>
                            <Nav.Link onClick={()=>setScrollTo('Contact')}>Contact us</Nav.Link>
                            <NavDropdown title="Dropdown" id="basic-nav-dropdown" className='d-none'>
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar