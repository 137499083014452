import React from 'react'
import { Link } from 'react-router-dom'
import "../css/Events.css"
import { dark, light } from './Colors'
import { useLightDarkContext } from './LightDarkContext'
import { motion } from 'framer-motion'

const Events = () => {
    const { isDark } = useLightDarkContext()
    const events = [
        {
            id: 1,
            name: "Problem Solver",
            details:"Separate the myths from reality as we explore the true nature of magic and its place in this modern world.",
            image: "/images/witch1.jpg"
        },
        {
            id: 2,
            name: "Bring back lost lover",
            details:"Discover detailed instructions and guidelines to perform this ritual safely and effectively.",
            image: "/images/lovers.jpg"
        },
        {
            id: 3,
            name: "Quick wealth",
            details:"Unlock doors of wealth that could not have been possible had you not tapped into the spiritual realm.",
            image: "/images/img3.jpg"
        },
        {
            id: 4,
            name: "Settle your relationship",
            details:"This is exactly what my magic is all about. Let us find peace together in that chaotic affair of yours.",
            image: "/images/img4.jpg"
        },
    ]
    return (
        <div className='events'>
            <div className='main'>
                <h2>CONSEQUENCES & KARMA</h2>
                <p>The concept of karma or the law of threefold return suggests that the energy one puts into the world, whether positive or negative , will return to them multiplied.</p>
                <p>This is exactly why my approach to my magical work emphasizes caution and responsibility in whatever you do.</p>
                <button>Get in Touch Now</button>
            </div>
            <div className='main'>
                {events.map(item => (
                    <div key={item.id} className='child' style={{background:`url(${item.image})`, backgroundSize:"cover"}}>
                        <div className='div'>
                            <h3 className='pb-2'>{item.name}</h3>
                            <p>{item.details}</p>
                        
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default Events