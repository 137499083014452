import React, { useState, useEffect, useRef } from 'react'
import NavBar from './Nav'
import "../css/Home.css"
import { motion, AnimatePresence, animate } from "framer-motion"
import { dark, light } from './Colors'
import { useLightDarkContext } from './LightDarkContext'
import Services from './Services'
import Testimonials from './Testimonials'
import Events from './Spells'
import Team from './Team'
import Footer from './Footer'
import How from './How'
import Loader from './Loader'
import Contact from './Contact'
import About from './About'
import SimpleImageSlider from "react-simple-image-slider";
import { useScrollContext } from './ScrollContext'
//import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const Home = () => {
    const { isDark } = useLightDarkContext()
    const { scrollTo } = useScrollContext()
    const [loading, setLoading] = useState(true)
    const homeRef = useRef(null)
    const aboutRef = useRef(null)
    const contactRef = useRef(null)
    const [view, setView] = useState(false)


    useEffect(() => {
        if (scrollTo === 'Home' && homeRef.current) {
            scrollToRef(homeRef);
        } else if (scrollTo === 'About' && homeRef.current) {
            scrollToRef(aboutRef);
        } else if (scrollTo === 'Contact' && homeRef.current) {
            scrollToRef(contactRef);
        }
    }, [scrollTo]);

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            // Calculate scroll percentage
            const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrolled = (window.scrollY / scrollHeight) * 100;

            // Check if scrolled 10%
            if (scrolled >= 20) {
                console.log("You have scrolled 10% of the page!");
                setView(true)
            } else {
                setView(false)
            }
        };

        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up function
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once


    const TypingDeletingComponent = ({ words }) => {
        const [currentIndex, setCurrentIndex] = useState(0);
        const [currentText, setCurrentText] = useState('');
        const [isDeleting, setIsDeleting] = useState(false);

        useEffect(() => {
            // Function to handle typing and deleting effect
            const handleTypingDeleting = async () => {
                const word = words[currentIndex].text;

                if (!isDeleting) {
                    // Typing out
                    for (let i = 0; i <= word.length; i++) {
                        setCurrentText(word.substring(0, i));
                        await new Promise(resolve => setTimeout(resolve, 100)); // Adjust speed here
                    }
                    setIsDeleting(true);
                } else {
                    // Deleting
                    for (let i = word.length; i >= 0; i--) {
                        setCurrentText(word.substring(0, i));
                        await new Promise(resolve => setTimeout(resolve, 50)); // Adjust speed here
                    }
                    setIsDeleting(false);
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
                }
            };

            // Start typing/deleting effect
            handleTypingDeleting();
        }, [currentIndex, isDeleting, words]);

        return (
            <p className='h5'>{currentText}</p>
        );
    };

    const wordsArray = [
        { text: 'I am a sanctuary for those who are curious about the darker aspects of magic.' },
        { text: 'You will find a wealth of knowledge and resources as you trust me to guide you on your journey.' },
        { text: 'From historical insights to practical guides,' },
        { text: 'Embark on a journey of discovery with me and unlock the mysteries that have captivated you for generations.' }
    ];

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])

    const images = [
        "/images/front.jpg",
        "/images/lovers.jpg",
        "/images/magic.jpg",
    ];

    return (
        loading ? <Loader /> :
            <div>
                <div className='header' ref={homeRef}>
                    <NavBar />
                    <AnimatePresence>
                        <motion.div className='motioned'>
                            <motion.div initial={{ scale: 0, y: 500 }} animate={{ scale: 1, y: 0 }} transition={{ damping: 10 }} className='headerContents' >
                                <h2 style={{ color: isDark ? dark.color : light.color }}>Welcome to the Mystical World of Black Magic!</h2>
                                <p style={{ color: "white" }}>Seekers of ancient wisdom and hidden truths, this portal is dedicated to the enigmatic world of black magic. Explore the shadows and uncover the secrets that have been shrouded in the mystery of centuries at almost <b>zero cost.</b>
                                </p>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: -100, x: -200 }}
                                animate={{ opacity: 1, y: 0, x: 0 }}
                                transition={{
                                    //type: "spring", bounce: 0.25,
                                    ease: "linear",
                                }}
                                className='slidderImages'>
                                <SimpleImageSlider
                                    style={{ margin: '0 auto' }}
                                    width={'100%'}
                                    height={'100%'}
                                    images={images}
                                    showBullets={true}
                                    showNavs={true}
                                    autoPlay={true}
                                    autoPlayDelay={5}
                                    navMargin={20}
                                    navSize={20}
                                    slideDuration={2}
                                    loop={true}
                                />
                            </motion.div>
                            <img src='/images/front.jpg' className='d-none' alt='magician' />
                            <TypingDeletingComponent words={wordsArray} />
                        </motion.div>
                    </AnimatePresence>
                </div>

                <div ref={aboutRef}>
                    <Services />
                    <How />
                    <Events />
                    <About />
                </div>
                <div ref={contactRef}>
                    <Testimonials />
                    <Contact />
                    <Footer />
                </div>
                <AnimatePresence>

                </AnimatePresence>
                {view &&
                    <motion.button
                        key={view}
                        whileTap={{
                            scale: 0.8,
                            rotate: -90,
                            borderRadius: "100%"
                        }}
                        whileHover={{ scale: 1.1 }}
                        initial={{ scale: 0, y: 100 }}
                        animate={{ scale: 1, y: 0, rotate: 360 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                        className='btn btn-sm btn-success scrollButton'
                        onClick={() => scrollToRef(homeRef)}><i class="fa-solid fa-circle-chevron-up"></i></motion.button>
                }
            </div>
    )
}

export default Home